
.content {

}
.right-panel .navbar {
}

.right-panel .navbar-nav .nav-link {
    padding:5px 10px 5px 10px;
    font-size: 1rem;
    border-style: solid;
    border-width: 1.4px;
    border-color: #e4e4e4;

    
}
.iconCopy {
    padding: 5px 10px 5px 10px;
    font-size: 1rem;
    margin-left:10px;
    height:38px;
    max-width:38px;
}

    .right-panel .navbar-nav .nav-link:hover {
        color: #28a745;
        background-color: #eaeaea !important;
    }
.nav-link.active {
    color: #28a745 !important;
    font-weight: bold;
    background-color: #f0f0f0;
}
.iconCustom {
    color: #28a745;
    
}
buttonBack{
    text-decoration:none !important;
    padding-left:5px;
    display:none !important;
}
.tooltipCustom {
    position: fixed; 
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    background-color: #333; 
    color: #fff; 
    padding: 10px 15px; 
    border-radius: 8px; 
    opacity: 0.9; 
    z-index: 1000; 
    pointer-events: none; 
    white-space: nowrap;
}

.iconHoverCustom :hover {
    color: #368d58
}

.navbarCustom {

}
.navLeft {
    border-radius: 10px 0px 0px 10px !important;
}

.navMiddle {
    border-radius: 0px 0px 0px 0px !important;
}

.navRight {
    border-radius: 0px 10px 10px 0px !important;
}
.navLinkTooltip {
    position: relative;
    display: inline-block;
}

    .navLinkTooltip .tooltipText {
        visibility: hidden;
        width: 80px; 
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        top: 125%; 
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
    }

    .navLinkTooltip:hover .tooltipText {
        visibility: visible;
        opacity: 0.8;
    }


a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.modal-body .row {
    margin-bottom: 8px;
}

table.table {
    white-space: nowrap;
}


.customAlert {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding:10px;
}

    .customAlert.success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
    .customAlert.danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
    .customAlert.warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }
    .customAlert.info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }


    @keyframes fadeIn {
        0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}